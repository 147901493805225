import "./App.css";
import MobilePhoto from "../src/images/mobile-image.png";
import { useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import sliderItem2 from "./assets/images/slider/02.jpeg";
import sliderItem3 from "./assets/images/slider/03.jpeg";
import sliderItem4 from "./assets/images/slider/04.jpeg";
import sliderItem5 from "./assets/images/slider/05.jpeg";

function App() {
  const [setSliderRef] = useState(null);

  const sliderSettings = {
    dots: false,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  const imageSliderItem = [
    { src: MobilePhoto, name: "slider-01" },
    { src: sliderItem2, name: "slider-01" },
    { src: sliderItem3, name: "slider-02" },
    { src: sliderItem4, name: "slider-03" },
    { src: sliderItem5, name: "slider-04" },
  ];

  const [autoplay, setAutoplay] = useState(true);

  return (
    <div className="flex items-center justify-center relative">
      {autoplay && (
        <div className="overlay">
          <div
            id="defaultModal"
            tabindex="-1"
            aria-hidden="true"
            className="flex items-center justify-center fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full"
          >
            <div className="relative w-full max-w-2xl max-h-full">
              <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                  <h3 className="flex items-center justify-center text-xl font-semibold text-gray-900 text-center dark:text-white">
                    Ahmet ÖRNEKBAŞ Kimdir?
                  </h3>
                  <button
                    onClick={() => setAutoplay(false)}
                    type="button"
                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    data-modal-hide="defaultModal"
                  >
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span class="sr-only">Kapat</span>
                  </button>
                </div>
                <div class="p-6 space-y-6">
                  <p class="text-base leading-relaxed text-gray-300 dark:text-gray-300">
                    1961'de Bolu'da doğdu. İlk, orta ve lise eğitimini Bolu'da
                    tamamlayan Ahmet Örnekbaş, 1982 yılında İstanbul Teknik
                    Üniversitesi'ne bağlı olan Düzce Meslek Yüksekokulu İşletme
                    ve Muhasebe Bölümü'nden mezun oldu. 1979 yılından bu yana ev
                    tekstili alanında mağaza işletmektedir. Örnekbaş evli ve iki
                    çocuk babasıdır.
                  </p>
                  <p class="text-base leading-relaxed text-gray-300 dark:text-gray-300">
                    Bolu Türk Ocağı'nda dört yıl boyunca Yönetim Kurulu Üyeliği
                    görevi üstlenen Örnekbaş, Boluspor Kulübü'nde de yine iki
                    yıl boyunca Ticari İlişkiler, Alım Satım ve İşletmeler
                    Asbaşkanlık görevlerini yürüttü. 1999-2004 yıllar arasında
                    Bolu Belediye Meclis Üyeliği yaptı. 30 yıla yakın süredir
                    siyasetin içerisinde yer alan Örnekbaş, 2017 yılında Meral
                    Akşener liderliğinde kurulan İYİ Parti'nin Bolu'da kurucu il
                    yönetim kurulu üyeliği ve 2018-2023 yıllar arasında İYİ
                    Parti Bolu İl Başkanlığı görevini üstlendi.
                  </p>
                </div>
                <div class="flex items-center justify-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                  <button
                    data-modal-hide="defaultModal"
                    type="button"
                    class="text-black mr-10 bg-yellow-400 hover:bg-yellow-400 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-yellow-400 dark:hover:bg-yellow-500 dark:focus:ring-yellow-400"
                  >
                    <a
                      target="_blank"
                      href="https://turkiyetarihyazacak.com/assets/pdfs/beyanname-ozet.pdf"
                    >
                      Seçim Beyannamesi
                    </a>
                  </button>
                  <button
                    data-modal-hide="defaultModal"
                    type="button"
                    class="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                  >
                    <a
                      target="_blank"
                      href="https://turkiyetarihyazacak.com/assets/pdfs/14.pdf"
                    >
                      Bolu Kalkınma Raporu
                    </a>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="w-full">
        <Slider ref={setSliderRef} {...sliderSettings}>
          {imageSliderItem?.map((feature, index) => (
            <div className="imagesliderwrapper">
              <img className="rounded-lg" key={index} src={feature?.src} />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default App;
